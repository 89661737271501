<template>
  <th
    ref="resizableColumn"
    class="relative"
    :style="{
      'min-width': columnWidth && isColumnResizable ? `${columnWidth}px` : null
    }"
  >
    <slot />
    <!-- resizer element -->
    <div
      v-if="isColumnResizable"
      :class="[
        resizerClass,
        isResizing ? activeResizerClass : ''
      ]"
      :style="{
        height: `${columnResizerHeight}px`
      }"
      @mousedown.stop="handleMouseDown"
      @click.stop
    />
  </th>
</template>

<script>
import { computed, nextTick, onMounted, ref, watch } from 'vue';

export default {
    name: 'BaseResizableColumn',

    props: {
        modelValue: {
            type: [Number, Object],
            default: null,
            validator: (value) => typeof value === 'object' ? value === null : true,
            description: 'width of table column in pixels(object type is for null value only)'
        },
        resizerClass: {
            type: String,
            default: 'absolute top-0 -right-0.5 w-1.5 cursor-col-resize select-none border-r-3 border-transparent hover:border-blue-600 transition-all duration-300',
            description: 'classes applied to resizer'
        },
        activeResizerClass: {
            type: String,
            default: 'border-blue-600',
            description: 'classes applied when resizer is active'
        },
        tableSelector: {
            type: String,
            required: true,
            description: 'html query selector string of parent table'
        },
        tableRows: {
            type: Number,
            default: 0,
            validator: (value) => value >= 0,
            description: 'Number of rows in parent table(use this if number of rows in table changes post rendering)'
        },
        cursorActiveClass: {
            type: String,
            default: 'cursor-col-resize',
            validator: (value) => value && value.length > 0,
            description: 'classes added to cursor while resizing column'
        },
        isColumnResizable: {
            type: Boolean,
            default: true,
            description: 'disables/enables resizing behaviour of column'
        }
    },

    emits: [
        'update:modelValue',
        'resizingStart',
        'resizingEnd'
    ],

    setup (props, { emit }) {
        // 2-way binding of column width
        const columnWidth = props.modelValue === null ? ref(0) : computed({ // maintain local component state only if modelValue is not provided
            get () {
                return props.modelValue;
            },
            set (newValue) {
                emit('update:modelValue', newValue);
            }
        });

        // column resizer logic
        const columnResizerHeight = ref(0);
        const updateColumnResizerHeight = async () => {
            try {
                await nextTick(); // wait for DOM to update
                if (props.tableSelector) {
                    const table = document.querySelector(props.tableSelector);
                    if (table) {
                        columnResizerHeight.value = table.clientHeight;
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };
        watch(
            () => props.tableRows,
            () => {
                updateColumnResizerHeight();
            }
        );
        onMounted(() => {
            updateColumnResizerHeight();
        });

        // column resizing logic
        let initialX = null;
        let initialColumnWidth = null;
        const resizableColumn = ref(null);
        const isResizing = ref(false);
        const handleMouseDown = (event) => {
            initialX = event.clientX;
            initialColumnWidth = resizableColumn.value.clientWidth;
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            document.body.classList.add(props.cursorActiveClass);
            isResizing.value = true;
            emit('resizingStart');
        };
        const handleMouseMove = (event) => {
            const distanceX = event.clientX - initialX;
            columnWidth.value = initialColumnWidth + distanceX;
        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.body.classList.remove(props.cursorActiveClass);
            isResizing.value = false;
            emit('resizingEnd');
        };

        return {
            // 2-way binding of column width
            columnWidth,
            // column resizer
            columnResizerHeight,
            // column resizing logic
            isResizing,
            resizableColumn,
            handleMouseDown
        };
    }
};
</script>
